import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import styled from '@emotion/styled'
import { useSiteMetadata } from '@hooks/use-site-metadata'
import { IoSearchOutline } from 'react-icons/io5'
import NavSearch from '@components/NavSearch'
import useScrollBlock from '../../hooks/useScrollBlock'
import ShareButtons from '@components/ShareButtons'

const Navbar = ({ show }) => {
  const [isOpen, setNav] = useState(false)
  const { menuLinks } = useSiteMetadata()
  const [searchState, setSearchState] = useState(false)
  const [blockScroll, allowScroll] = useScrollBlock()

  useEffect(() => {
    searchState ? blockScroll() : allowScroll()
  }, [searchState])

  const toggleNav = () => {
    setNav((isOpen) => !isOpen)
  }

  return (
    <>
      <NavWrapper className={show ? 'active' : 'hidden'}>
        <div className="masthead flex-Section">
          <button
            className={
              isOpen
                ? `${'toggle-btn'} ${'toggle-btn-active'}`
                : `${'toggle-btn'}`
            }
            type="button"
            onClick={toggleNav}
            aria-label="Menu Button"
          >
            <span></span>
            <span></span>
            <span></span>
          </button>
          <ImgWrapper>
            <Link to="/">
              <StaticImage
                src="../../../static/images/whisk-navbar.png"
                alt="Whisk Home Navbar"
                objectFit="cover"
              />
            </Link>
          </ImgWrapper>
          <div className="sharebutton-wrapper">
            <ShareButtons />
          </div>
        </div>
        <>
          <ul
            className={
              isOpen ? `${'nav-links'} ${'show-nav'}` : `${'nav-links'}`
            }
          >
            {menuLinks.map((link, index) => (
              <li key={index}>
                <Link to activeclass="active" to={link.slug}>
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
        </>
        <SearchButton onClick={() => setSearchState((prevState) => !prevState)}>
          <IoSearchOutline />
        </SearchButton>
        {searchState && (
          <NavSearch
            searchState={searchState}
            setSearchState={setSearchState}
          />
        )}
      </NavWrapper>
      {/* <LogoWrapper>
        <StaticImage
          src="../../../static/images/the-rising-rose-logo-transparent.png"
          loading="eager"
          alt="The Rising Rose Logo"
        />
      </LogoWrapper> */}
    </>
  )
}

const ImgWrapper = styled.div`
  display: inline-block;
  width: 100%;
  max-width: 60px;

  &::hover {
    pointer: cursor;
  }

  @media (min-width: ${(props) => props.theme.screen.medium}) {
    display: none;
  }
`

const NavWrapper = styled.nav`
  position: fixed;
  z-index: 10;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-content: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  box-sizing: border-box;
  background: white;

  .masthead {
    display: flex;
    z-index: 3;
    width: 100%;
    max-width: 53vw;
    justify-content: space-between;
    @media screen and (max-width: ${(props) => props.theme.screen.medium}) {
      align-items: center;
    }

    .sharebutton-wrapper {
      display: none;
      @media (min-width: ${(props) => props.theme.screen.medium}) {
        display: block;
      }
    }
  }

  .nav-links {
    display: flex;
    flex-direction: column;
    justify-content: end;
    text-align: center;
    background: white;
    margin: 0;
    height: max-content;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    transition: 0.3s ease-in;
    transform: translateY(-110%);
    margin-top: 0.5em;

    li {
      list-style: none;
      font-size: 1em;
      font-weight: 400;
      margin-left: 0;
      padding: 0.75rem 0;
      @media screen and (max-width: ${(props) => props.theme.screen.medium}) {
        border: 1px solid #ececec;
      }

      a {
        text-decoration: none;
        text-transform: capitalize;
        color: ${(props) => props.theme.colors.text};
        transition: 0.3s;
        &.active {
          color: #e609b5;
        }
      }

      &:hover {
        cursor: pointer;
        a {
          color: ${(props) => props.theme.colors.secondary};
        }
      }
    }
    &.show-nav {
      transform: translateX(0%);
      transform: translateY(30%);
      margin-top: 0;
    }
  }

  .toggle-btn {
    width: 40px;
    height: 40px;
    padding: 5px;
    background-color: transparent;
    border: none;

    span {
      display: block;
      width: 30px;
      height: 2px;
      background-color: black;
      transition: 0.2s ease-in;
      &:nth-of-type(1) {
        transform: translateY(-5px);
      }
      &:nth-of-type(3) {
        transform: translateY(5px);
      }
    }

    &.toggle-btn-active {
      span {
        &:nth-of-type(1) {
          transform: translateY(2px) rotate(45deg);
        }
        &:nth-of-type(2) {
          opacity: 0;
          transform: translateX(-100%);
        }
        &:nth-of-type(3) {
          transform: translateY(-2px) rotate(-45deg);
        }
      }
    }
  }

  @media (min-width: ${(props) => props.theme.screen.medium}) {
    .masthead {
      flex-direction: column;
      justify-content: center;
    }
    .toggle-btn {
      display: none;
    }
    .nav-links {
      background: transparent;
      flex-direction: row;
      margin-left: auto;
      position: relative;
      transform: translateX(0);
      transition: none;
      width: auto;
      li {
        margin-left: 1rem;
      }
    }
  }
`

const SearchButton = styled.button`
  margin-left: 1em;
`

export default Navbar
