import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import algoliasearch from 'algoliasearch/lite'
import {
  Configure,
  createInfiniteHitsSessionStorageCache,
  InstantSearch,
  SearchBox,
} from 'react-instantsearch-dom'
import CustomInfiniteHits from '@components/InfiniteHits'
import { IoArrowBackCircleOutline } from 'react-icons/io5'

const searchClient = algoliasearch(
  '2SZNF2E322',
  '3d3a1e0019b22283fb8c9e77990f4afd'
)
const sessionStorageCache = createInfiniteHitsSessionStorageCache()

const NavSearch = ({ setSearchState }) => {
  return (
    <Section>
      <Container>
        <InstantSearch
          searchClient={searchClient}
          indexName="THE_WHISTLING_COOK_BLOG"
        >
          <Configure hitsPerPage={12} />
          <SearchContainer>
            <GoBack onClick={() => setSearchState(false)}>
              <IoArrowBackCircleOutline />
            </GoBack>
            <SearchBox />
          </SearchContainer>
          <CustomInfiniteHits cache={sessionStorageCache} minHitsPerPage={12} />
        </InstantSearch>
      </Container>
    </Section>
  )
}

// Todo: make sure search results are scrollable
const Section = styled.section`
  overflow-y: auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: ${(props) => props.theme.colors.background};
  height: 100vh;
  width: 100vw;
  transition: 0.3s ease-in;
  margin: 0;
  opacity: 1;
`
const Container = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  max-width: 960px;
  margin: 0 auto;
  padding: 1.5em;
`
const SearchContainer = styled.div`
  width: 900px;
  max-width: 85vw;
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  font-size: 1.3em;
  white-space: nowrap;
`

const GoBack = styled.div`
  padding-right: 0.5em;
`

export default NavSearch
