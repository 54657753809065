import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Navbar from '@components/Navbar'

const Menu = () => {
  const [scrollPos, setScrollPos] = useState(0)
  const [show, setShow] = useState(true)

  // const scrollEl = useRef(null);

  useEffect(() => {
    // const el = scrollEl.current;

    window.addEventListener('scroll', handleScroll, { passive: true })
    return () => window.removeEventListener('scroll', handleScroll)
  }, [scrollPos])

  const handleScroll = () => {
    setShow(document.body.getBoundingClientRect().top >= scrollPos)
    setScrollPos(document.body.getBoundingClientRect().top)
  }

  return (
    <MenuWrapper>
      <Navbar show={show} />
    </MenuWrapper>
  )
}

const MenuWrapper = styled.div`
  .active {
    visibility: visible;
    transition: all 200ms ease-in;
  }

  .hidden {
    visibility: hidden;
    transition: all 200ms ease-in;
    transform: translate(0, -100%);
  }
`

export default Menu
