import React, { useEffect } from 'react'
import { connectInfiniteHits } from 'react-instantsearch-dom'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import Card from '@components/Card'
import CardList from '@components/CardList'

const InfiniteHits = ({
  hits,
  hasPrevious,
  refinePrevious,
  hasMore,
  refineNext,
}) => {
  let sentinel = null
  const onSentinelIntersection = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (entry.isIntersecting && hasMore) {
          refineNext()
        }
      }
    })
  }

  useEffect(() => {
    let observer = new IntersectionObserver(onSentinelIntersection)
    observer.observe(sentinel)
    return () => {
      observer.disconnect()
    }
  }, [hasMore])

  return (
    <HitContainer>
      {/* {hasPrevious &&
        <button disabled={!hasPrevious} onClick={refinePrevious}>
          Show previous
        </button>
      } */}
      <CardList>
        {hits.map((hit) => (
          <Card key={hit.id} {...hit} basePath={''} />
        ))}
        <li className="ais-InfiniteHits-sentinel" ref={(c) => (sentinel = c)} />
      </CardList>
      {/* {hasMore && 
        <button onClick={refineNext}>
          Show more
        </button>
      } */}
    </HitContainer>
  )
}

const HitContainer = styled.div`
  max-width: 90%;
  margin: 0 auto;
  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    margin: initial;
  }
  @media screen and (min-width: ${(props) => props.theme.screen.large}) {
    // todo: better solution for scaling
    width: 900px;
    // height: 500px;
  }
`

InfiniteHits.propTypes = {
  hits: PropTypes.array.isRequired,
  hasMore: PropTypes.bool.isRequired,
  hasPrevious: PropTypes.bool.isRequired,
  refine: PropTypes.func.isRequired,
  refinePrevious: PropTypes.func.isRequired,
}

const CustomInfiniteHits = connectInfiniteHits(InfiniteHits)

export default CustomInfiniteHits
