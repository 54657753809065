import React from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { useSiteMetadata } from '@hooks/use-site-metadata'
import { StaticImage } from 'gatsby-plugin-image'

const Container = styled.footer`
  margin: 1em auto;
  max-width: ${(props) => props.theme.sizes.maxWidth};
  border-top: 1px solid gainsboro;
  position: relative;
`

const SiteMap = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-items: flex-start;
  border-bottom: 1px solid gainsboro;
`

const Wrapper = styled.div`
  height: max-content;
  width: 50%;
  margin: 0.5em auto;
`

const SiteLinks = styled.div`
  display: flex;
  padding-right: 1em;
  padding-top: 1em;
  @media screen and (min-width: ${(props) => props.theme.screen.small}) {
    border-right: 1px solid gainsboro;
  }
`

const Title = styled.h3`
  margin-bottom: 0.5em;
  font-size: 1.1em;
  padding: 0.5em 0;
  border-bottom: 0.5px solid gainsboro;
`

const List = styled.ul`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  padding: 1em 0 2em;
  margin: 0 1.5em;
`

const Item = styled.li`
  display: inline-block;
  padding: 0.25em 0;
  width: 100%;
  @media screen and (min-width: ${(props) => props.theme.screen.small}) {
    width: auto;
  }

  a {
    transition: all 0.2s;
    color: ${(props) => props.theme.colors.text};
    &:hover {
      color: ${(props) => props.theme.colors.highlight};
    }
  }
`

const Credit = styled.div`
  text-align: center;
  margin: 1em auto;
  font-size: 0.8em;
`

const Footer = () => {
  const { menuLinks, socialLinks } = useSiteMetadata()

  return (
    <Container>
      <SiteMap>
        <SiteLinks>
          <List>
            <Title>Info</Title>
            {menuLinks.map((link, index) => (
              <Item key={index}>
                <Link to activeclass="active" to={link.slug}>
                  {link.name}
                </Link>
              </Item>
            ))}
          </List>
          <List>
            <Title>Connect</Title>
            {socialLinks.map((link, index) => (
              <Item key={index}>
                <a href={link.slug} target="_blank" rel="noopener noreferrer">
                  {link.name}
                </a>
              </Item>
            ))}
          </List>
        </SiteLinks>
        <Wrapper>
          <Title>Our Favorites</Title>
          <StaticImage
            src="../../static/images/footer-instagram-placeholder.png"
            alt="About Olivia Rose"
            placeholder="blurred"
            objectFit="cover"
          />
        </Wrapper>
      </SiteMap>
      <Credit>
        {/* <Item>
          <span>
            <a
              href="https://github.com/wyrd00"
              target="_blank"
              rel="noopener noreferrer"
            >
              Design & website
            </a>{' '}
            by{' '}
            <a
              href="https://github.com/wyrd00"
              target="_blank"
              rel="noopener noreferrer"
            >
              amy ❤️
            </a>
            .&nbsp;
          </span>
        </Item> */}
        <Item>Copyright © 2020-2021 The Rising Rose.</Item>
      </Credit>
    </Container>
  )
}

export default Footer
