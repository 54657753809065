import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'
import { AnimatedLink } from '@styles/globalComponents'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Post = styled.li`
  position: relative;
  margin: 0 0 4em 0;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: background 0.2s;
  display: flex;
  flex-flow: column;

  @media screen and (min-width: ${(props) => props.theme.screen.small}) {
    flex: ${(props) =>
      props.withText ? '0 0 100%' : props.nowrap ? '0 0 32%' : '0 0 49%'};
    margin: 0 0 2vw 0;
  }

  @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
    flex: ${(props) => (props.withText ? '0 0 32%' : '0 0 32%')};
  }

  .gatsby-image-wrapper {
    height: 0;
    padding-bottom: 140%;
    @media screen and (min-width: ${(props) => props.theme.screen.medium}) {
      padding-bottom: ${(props) =>
        props.nowrap ? '140%' : props.withText ? '140%' : '120%'};
    }
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`

const StyledImg = styled(GatsbyImage)`
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  transition: 0.3s all ease-in-out;

  &:hover {
    transform: scale(1.05);
  }
`

const Title = styled.h2`
  font-family: ${(props) => props.theme.fonts.header};
  font-size: 1.1em;
  line-height: 1.1em;
  margin: 1rem 0.3em 0.5rem;
`

const Date = styled.h3`
  margin: 0 1rem 0.5rem 1rem;
  color: gray;
  font-size: 0.75em;
`

const Excerpt = styled.p`
  margin: 0 1em 1em;
  line-height: 1.6;
  font-size: 0.85em;
`

const Card = ({
  slug,
  heroImage,
  title,
  publishDate,
  body,
  withText = false,
  nowrap = false,
  ...props
}) => {
  const image = getImage(heroImage)

  return (
    <>
      {heroImage && body && (
        <Post featured={props.featured} withText={withText} nowrap={nowrap}>
          <Link to={`${props.basePath}/${slug}/`}>
            <ImageWrapper>
              <StyledImg
                image={image}
                alt={title}
                backgroundColor={'#eeeeee'}
              />
            </ImageWrapper>
          </Link>
          <Link to={`${props.basePath}/${slug}/`}>
            <Title>{title}</Title>
          </Link>
          {withText && (
            <>
              <Date>{publishDate}</Date>
              <Excerpt
                dangerouslySetInnerHTML={{
                  __html: body.childMarkdownRemark.excerpt,
                }}
              />
              <div>
                <AnimatedLink>
                  <Link to={`${props.basePath}/${slug}/`}>Read More</Link>
                </AnimatedLink>
              </div>
            </>
          )}
        </Post>
      )}
    </>
  )
}

export default Card
