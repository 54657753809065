import React from 'react'
import styled from '@emotion/styled'
import {
  FacebookShareButton,
  FacebookIcon,
  // FacebookShareCount,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'

const ShareButtons = ({ title, url, twitterHandle, tags, body }) => {
  
  return (
    <SocialMediaLinks>
      <FacebookShareButton url={'https://www.facebook.com/TheRisingRose8/'}>
        <FacebookIcon size={36} round={true} />
        {/* <FacebookShareCount>
          {shareCount => <span className="myShareCountWrapper">{shareCount}</span>}
        </FacebookShareCount> */}
      </FacebookShareButton>

      {/* <TwitterShareButton
        url={url}
        title={title}
        via={twitterHandle}
        hashtags={tags}
      >
        <TwitterIcon size={36} round={true} />
      </TwitterShareButton> */}

      <PinterestShareButton url={url} title={title} tags={tags}>
        <PinterestIcon size={36} round={true} />
      </PinterestShareButton>

      <EmailShareButton url={url} subject={title} body={body}>
        <EmailIcon size={36} round={true} />
      </EmailShareButton>
    </SocialMediaLinks>
  )
}

const SocialMediaLinks = styled.div`
  width: 100%;

  > * {
    margin: 1em 0.5em;
  }

  svg circle {
    fill: black;
    transition: 0.3s all ease-in-out;
  }

  svg:hover circle {
    fill: pink;
  }
`

export default ShareButtons
