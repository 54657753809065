import styled from '@emotion/styled'

export const Section = styled.section`
  margin: 0 auto auto;
  width: 100%;
  max-width: ${(props) => props.theme.sizes.maxWidth};
  padding: 5em 1.5em 2em;
  flex-grow: 1;
`

export const Button = styled.a`
  text-transform: uppercase;
  width: 3em;
  height: 3em;
  background: ${(props) => props.theme.colors.primary};
  text-align: center;
  text-decoration: none;

  &:focus {
    outline: none;
  }
  &:hover {
    background: ${(props) => props.theme.colors.highlight};
  }
`

// may not need this -- revisit later
export const FormTextarea = styled.textarea`
  color: #696969;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  font-weight: 400;
  background-color: ${(props) => props.theme.colors.background};
  margin-bottom: 7px;
  padding: 1em;
  border: 2px solid #efece6;
  width: 100%;
`

export const FormEmail = styled.input`
  margin: 0 0 1em 0;
  width: 100%;
  @media (min-width: ${(props) => props.theme.screen.small}) {
    width: 49%;
  }
`

export const CategoryTitle = styled.h2`
  font-family: ${(props) => props.theme.fonts.header};
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: normal;
  line-height: 62.4px;
  text-transform: none;
  color: #5c5c5c;
  margin: 1em 0;
  text-align: center;

  ${(props) =>
    props.line &&
    `
    &::after {
      // content: '';
      // position: absolute;
      // width: 100%;
      // max-width: 890px;
      // border-top: 0.5px solid black;
      // margin: 0.5em 0 0 1rem;
      content: "";
      display: block;
      height: 50px;
      width: 0;
      border-left: 1px solid #252121;
      margin: 10px auto 15px;
    }
  `}
`

export const AnimatedLink = styled.div`
  display: inline-block;
  font-weight: bold;
  line-height: 1;
  text-transform: uppercase;
  letter-spacing: 0.15em;
  font-size: 13px;
  border-bottom: 1px solid ${(props) => props.theme.colors.secondary};
  box-shadow: inset 0 -4px 0 ${(props) => props.theme.colors.secondary};
  padding: 0 4px;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  color: ${(props) => props.theme.colors.text};

  &:hover {
    box-shadow: inset 0 -22px 0 #e3c7c4;
  }
`
