import { css } from '@emotion/react'
export const globalStyles = css`
  /* http://meyerweb.com/eric/tools/css/reset/
 v2.0 | 20110126
 License: none (public domain)
*/

  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&family=Montserrat&family=Open+Sans&display=swap');

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  embed,
  figure,
  figcaption,
  footer,
  header,
  hgroup,
  menu,
  nav,
  output,
  ruby,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    /* stylelint-disable-next-line */
    font: inherit;
    vertical-align: baseline;
  }

  /* Added to Fix Footer to bottom of viewport */
  html {
    overflow-x: hidden;
  }

  html,
  body {
    box-sizing: border-box;
    background: white;
    height: 100%;
    font-size: calc(18px + (22 - 18) * ((100vw - 320px) / (1400 - 320)));
  }

  *, *::before, *::after {
    box-sizing: inherit;
  }

  .siteRoot {
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  .siteContent {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
  }
  footer {
    width: 100%;
  }

  /* End Fix to Place Footer on Bottom of Viewport */

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section {
    display: block;
  }

  @media screen and (min-width: 35em) {
    html {
      margin-right: calc(-100vw + 100%);
    }
  }

  ol,
  ul,
  li {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote::before,
  blockquote::after,
  q::before,
  q::after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body {
    line-height: 1;
    font-size: 100%;
    font-variant-ligatures: none;
    text-rendering: optimizeLegibility;
    text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
    font-weight: 400;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
  }

  button,
  input,
  textarea,
  select {
    font-family: inherit;
    font-size: inherit;
    background: none;
    border: none;
    appearance: none;
    border-radius: 0;
    resize: none;
    &:invalid {
      box-shadow: none;
    }
    &:focus {
      outline: 5px auto #5e9ed6;
      outline: 5px auto -webkit-focus-ring-color;
    }
  }

  a {
    color: black;
    text-decoration: none;
  }

  body:not(.user-is-tabbing) button:focus,
  body:not(.user-is-tabbing) input:focus,
  body:not(.user-is-tabbing) select:focus,
  body:not(.user-is-tabbing) textarea:focus,
  body:not(.user-is-tabbing) a:focus {
    outline: none;
  }

}

  .ais-SearchBox { 
    input {
      margin: 1em;
      border-bottom: 1px solid black;
      @media screen and (min-width: 50em) {
        width: 500px;
        max-width: 90vw;
      }
      @media screen and (min-width: 70em) {
        margin: 1em 0;
      }
    }
  }

  .ais-InstantSearch {
    max-width: 960px;
    overflow: hidden;
    margin: 0 auto;
  }

  .right-panel {
    margin-left: 260px;
  }

  // will use this css for regular pagination
  .ais-Pagination-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ais-Pagination-link {
    height: 100%;
    width: 100%;
  }

  .ais-Pagination-item, .ais-Pagination-link {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .ais-Pagination-item.ais-Pagination-item--page {
    margin-right: 4px;
  }
  .ais-Pagination-item.ais-Pagination-item--previousPage {
    margin-right: 1rem;
  }
  .ais-Pagination-item.ais-Pagination-item--nextPage {
    margin-left: calc(1rem - 4px);
  }
  .ais-Pagination-item--firstPage, .ais-Pagination-item--previousPage, .ais-Pagination-item--nextPage, .ais-Pagination-item--lastPage {
    background: none;
  }
  .ais-Pagination-item--disabled {
    opacity: 0.33;
  }
  
  .ais-Pagination-item {
    background-color: rgba(65, 66, 71, 0.08);
    border-radius: 4px;
    color: #414247;
    height: 38px;
    width: 38px;
  }

  .ais-Pagination-item, .ais-Pagination-link {
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .ais-Pagination-item--selected {
    background-color: #e2a400;
    background-image: linear-gradient( to bottom, rgba(255, 255, 255, 0.34), rgba(255, 255, 255, 0) );
    font-weight: bold;
  }
  
`
